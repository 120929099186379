.FirmEditProfile {
  display: flex;
  flex-flow: column;
  padding-top: 50px;
}

.firmEditProfile__label {
  font-weight: 600;
  font-size: 18px;
  padding-bottom: 20px;
}

.firmEditProfile__deactivate {
  display: flex;
  flex-flow: column;
}

.firmEditProfile__pic {
  width: 200px;
}

.firmEditProfile__picWrap {
  display: flex;
  flex-flow: column;
}

.firmEditProfile__accountStatus__label--active {
  font-weight: 600;
  font-size: 18px;
  color: green;
  background-color: greenyellow;
}

.firmEditProfile__accountStatus__label--active {
  font-weight: 600;
  font-size: 18px;
  color: green;
  background-color: greenyellow;
}

.firmEditProfile__accountStatus__label--deactivated {
  font-weight: 600;
  font-size: 18px;
  color: red;
  background-color: rgb(255, 172, 172);
}

.firmEditProfile__deactivate__btn {
  margin-top: 30px;
  background-color: red;
  color: #fff;
  padding: 10px;
  font-weight: 600;
  border: none;
  border-radius: 8px;
  &:hover {
    cursor: pointer;
    opacity: 0.8;
  }
}

.firmEditProfile__activate__btn {
  margin-top: 30px;
  background-color: greenyellow;
  color: green;
  padding: 10px;
  font-weight: 600;
  border: none;
  border-radius: 8px;
  &:hover {
    cursor: pointer;
    opacity: 0.8;
  }
}

//

.firmEditProfile__row1 {
  display: flex;
}

.firmEditProfile__row2 {
  display: flex;
  flex-flow: column;
  margin-top: 100px;
}

.firmEditProfile__row3 {
  display: flex;
  margin-top: 100px;
}

.form-check-input--firmEditProfile {
  width: 20px;
  height: 20px;
  margin-right: 10px;
  margin-top: 10px;
}

// table

// table

.priceTable {
  // display: flex;
  // flex-flow: column;
  // border-radius: 10px;
  overflow: hidden;
  perspective: 1px;
  border-collapse: collapse;
  table-layout: fixed;
  width: 100%;
  white-space: nowrap;
  border: 1px solid #becfe2;
  margin-top: 32px;
  background: #ffffff;
  margin-bottom: 100px;
  // box-shadow: 0 0 20px -10px rgba(115, 115, 115, 0.5);
}

.priceTable thead th {
  border-bottom: none;
}

.priceTable td {
  border: none;
  font-family: "Circularstd";
  font-weight: 200;
  text-align: left;
  white-space: nowrap;
  // overflow: hidden;
  text-overflow: ellipsis;
  font-size: 18px;
  padding: 15px 20px 10px 20px;
  width: 100%;
  color: #0b1151;

  @media screen and (max-width: 767px) {
    font-size: 16px;
  }
}

/*workflow priceTable - header*/
.priceTable th {
  font-family: "Circularstd";
  font-weight: 600;
  padding-top: 15px;
  padding-bottom: 15px;
  padding-left: 20px;
  text-align: left;
  background-color: #fff;
  border: 0;

  width: 100%;
  color: #0b1151;

  @media screen and (max-width: 767px) {
    font-size: 16px;
  }
}

.priceTable thead tr {
  display: flex;
  flex-flow: row;
  width: 100%;
}

// .priceTable th:last-child,
// .priceTable td:last-child {
//   text-align: right;
//   padding-right: 20px;
// }

.priceTable tbody {
  max-height: calc(95vh - 225px);
  display: block;
  overflow-y: auto;
  overflow-x: hidden;
}

.priceTable thead {
  display: block;
  overflow-x: hidden;
}

.priceTable tbody tr {
  display: flex;
}

.priceTable {
  tr:nth-child(odd) {
    background: #f4f9ff;
  }
}
