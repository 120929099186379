.lw-portal-select-list-container {
  display: flex;
  flex-flow: column;
  justify-content: center;
  margin-bottom: 14px;
}


.lw-portal-select-list-title {
  font-family: "DM Sans";
  font-size: 15px;
  font-weight: 700;
  color: #362727;
  margin-left: 5px;
}

.select-list-input {
  outline: none;
  font-size: 16px;
  font-family: "DM Sans";
  font-weight: 300;
  background: #FFFFFF;
  color: #111111;
  border-radius: 4px;
  border: 1px solid #D3B9B9;
  padding: 5px 20px;
  width: 130px;
  height: 40px;;
}

.select-list-input:focus {
  border: 1px solid #3689FF;
}

.select-list-input-small {
  font-size: 13px;
  font-family: "DM Sans";
  font-weight: 400;
  color: #9b9b9b;
  margin-top: 10px;
  margin-left: 1em;
}

.select-list-options {
  background-color: #ffffff;
  font-size: 20px;
}


.is-invalid {
  border: 1px solid red;
}


/* law portal - select field */

.law-portal-select-list-input {
  outline: none;
  font-size: 16px;
  font-family: "DM Sans";
  font-weight: 300;
  background: #FFFFFF;
    /* color: #111111; */
    color: #362727;
  border-radius: 4px;
  border: 1px solid #D3B9B9;
  padding: 5px 10px;
  /* width: 200px;
  height: 45px; */
  width: 175px;
  height: 40px;
}

.law-portal-select-list-input:focus {
  border: 1px solid #3689FF;
}

.is-invalid {
  border: 1px solid red;
}


/* external multi select list */
.css-1hwfws3 {
  min-height: 38px; 
}

.basic-multi-select {
  width: 327px;
}

.css-2b097c-container {
  /* border: 1px solid #D3B9B9; */
  border-radius: 8px;
}
