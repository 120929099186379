@import '../../../style/variables';

.logout-icon {
  margin-left: 10px;
  padding: 5px;
  height: 100%;
  background-color: #E8E1E1;
  border-radius: 50%;

  &:hover {
    stroke: #D01E1B;
    background-color: #F9E7E9;
    cursor: pointer;
  }
}





.admin-outer-container {
  display: flex;
  justify-content: space-between;
  align-items: center;



  width: 100%;
  padding-right: 50px;
  padding-left: 50px;
  background-color: #FFFFFF;
  height: 80px;
}

.admin-navbar-links-wrapper {
  display: flex;
}



  
.nav-item h3 {
  color: #412224;
  font-size: 1em;
  margin-top: .7em;
  margin-right: 2em;
}


.NavBar {
  background-color: #FFFFFF;
  height: 100vh;
  width: 90px;
  border-right: 1.4px solid $gray-light;
  flex-shrink: 0;
  padding-top: 30px;
  position: fixed;
  display: flex;
  flex-direction: column;
}

.navbar-logout-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
 
}

.navbar-logout-container > p{
margin-top: 17px;
}

.fa-sign-out-alt {
  
  margin-left: 10px;

	color: $gray-light;
  font-size: 35px;
	
  &:hover {
		cursor: pointer;
    color: #CF021A;
  }
}

.navbar-logo-container {
	display: flex;
  justify-content: center;
  margin-bottom: 60px;
  
	
  img {
		width: 50px;
  }
}

.navbar-links-container {
  @include flex-col;
}

.navbar-link {
	@include flex-center;
  width: 48px;
  height: 48px;
  border-radius: 8px;
  margin-bottom: 40px;
  background-color: rgb(226, 226, 226);
  
	
  img {
    width: 60%;
  }
	
  &.selected {
    background-color: $red;
  }

  &:hover {
    border: 1px solid $red;
    transition: .15s
  }
}
