.Practitioner {
    display: flex;
    flex-flow: column;
    // justify-content: center;
    // align-items: flex-start;
    height: 100%;
   
    // padding: 20px;
    padding-left: 50px;
    padding-right: 50px;
    padding-bottom: 50px;
    padding-top: 20px;
    

}


.doc-input-container {
    margin: 50px 0px;
    // border: 5px solid #ca9b9b;
    background-color: rgb(255, 247, 247);
    border-style: dashed;
    border-radius: 0px;
    padding-left: 20px;
    padding-right: 20px;
    height: auto;
    border: 2px dashed rgb(221, 171, 171);
   
    &:hover {
        opacity: .8;
        // border: 3px solid #ad6b6b;
        cursor: pointer;
        transition: .2s;
    }
    
}


.reviews-file-upload-input{ 
   
    padding: 50px;
    &:hover {
        opacity: .8;
        // border: 3px solid #ad6b6b;
        cursor: pointer;
        transition: .2s;
    }
}

