.CreateSignupLink {
    display: flex;
    flex-flow: column;
    // justify-content: center;
    align-items: flex-start;
    height: 80vh;
    
    // padding: 20px;
    padding-left: 50px;
    padding-right: 50px;
    padding-bottom: 50px;
    padding-top: 40px;
    

}

.cc-txt-dark{ 
    color: #3F2223;
}

.table {
    margin-top: 20px;
    overflow: hidden;
    background: #FFFFFF;
    box-shadow: 0 0 20px -10px rgba(115,115,115,0.50);
    
    overflow: hidden;
	perspective: 1px;

    
     width: 100%;
   
}





.cc-red-btn {
    border: 1px solid #9C9C9C;
    color: rgba(74,74,74,0.55);
  
    background-color: #FFFFFF;
    padding: 10px 30px 10px 20px;
    min-height: 50px;
    max-height: 50px;
    font-weight: 700;

    
    &:hover {
        cursor: pointer;
        color: #D0021B;
        border: 1px solid #D0021B;
        // background-color: #fdebeb;
    }

    &:focus {
        outline: none;
    }
}



.cc-title-btn-wrap{
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    min-width: 100%;
}

.copy-link {
    color: #00D209;
    font-weight: 700;
    &:hover {
        // color: #D0021B;
        transition: .2s;
        background-color: #E3FECA;
        cursor: pointer;
    }
}




/* Upload Document Input  ----------------------------------------------------------------  */

.doc-input-container {
    margin: 100px 0px;
    border: 5px solid #9BA9CA;
    border-style: dashed;
    border-radius: 20px;
    padding-left: 20px;
    padding-right: 20px;
    height: 100%;
}

/* bordered drop area */
.filepond--panel-root {
    border: 4px dashed #9BA9CA;
    opacity: 0;
}



.filepond--root {
    height: auto;
}


.filepond--item-panel {
    background-color: red;

}


/* Input Placeholder (document upload) ----------------------------------------------------------------  */

.filepond--drop-label {
    right: 0;
    color: #9BA9CA !important;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 0;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    font-size: 25px;
}


.filepond--drop-label label {
    cursor: default;
    font-size: 20px !important;
    font-weight: 400;
    text-align: center;
    line-height: 1.5;
}

.filepond--file {
    background-color: #FFFFFF;
    color: #395395 !important;
    font-size: 20px !important;
}


