.Dashboard {
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 20px;
}

.dashboard-page-link {
    display: flex;
    justify-content: center;
    align-items: center;
   

    font-family: 'DM Sans';
    font-weight: 500;
    font-size: 20px;
    background-color: #FFFFFF;
    color: rgba(74,74,74,0.55);
    padding: 20px 40px 20px 30px;
    border: 1px solid #9C9C9C;
  

    &:hover {
        cursor: pointer;
        border: 1px solid #D0021B;
        color: #D0021B;
    }
    &:focus {
        outline: none;
    }
}


.cc-flex-row {
    display: flex;
}

.cc-flex-column {
    display: flex;
    flex-flow: column;
}


.dashboard-card {
    display: flex;
    flex-flow: column;
    background-color: #FFFFFF;
    padding: 20px;
    width: 100%; 
}

.dashboard-card-1 > h3 { 
    color: #ffffff;
}

.dashboard-header-bar {
    display: flex;
    justify-content: space-between;
    width: 100%;
}