// @import 'reset';
@import 'variables';

html {
  background-color: #F6F0F0;
  font-family: 'DM Sans';
}

body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
  background-color: $white;
  overflow-y: scroll;
  background-color: #F6F0F0;
  font-family: 'DM Sans';
  color: #402224;
}

// .Main {
//   display: flex;
//   flex-flow: row;
// }

.copyright {
  text-align: center;
  font-size: .9em;
  color: #6c757d;
  margin-top: 60px;
}

.law-firm-portal-pg-spinner-container {
  @include portal-content-spacing;
  @include flex-column-center;
  width: 100%;
}