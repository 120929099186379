.CreateReviews {
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
    height: 50vh;
    
    // padding: 20px;
    padding-left: 50px;
    padding-right: 50px;
    padding-bottom: 50px;
    // padding-top: 40px;
}

.table-row {
    &:hover {
        background-color: rgb(255, 248, 248);
        // opacity: .7;
        cursor: pointer;
    }
}



.users-list-search-add-wrap {
    width: 100%;
    display: flex;
    flex-flow: row;
    justify-content: space-between;
    align-items: center;
  }


.users-search-field {
    // margin-top: 3em;
    // margin-right: 1.5em;
      padding: 7px 20px 7px 20px;
     
      
      border-radius: 10px;
      font-size: 20px;
      // background-color: transparent;
      font-weight: 200;
      // border: 1px solid #953939;
      box-shadow: 0 2px 20px -10px rgba(115,115,115,0.50);
  
      &::placeholder{ 
         color: rgb(168, 157, 157); 
      }
  }
  
  .users-search-field {
      color: rgb(168, 157, 157);
      background-color: #fff;
      border: none;
  }
  
  .users-search-field:focus {
      outline: none;
  }



  .back-btn {
      &:hover {
          stroke: #FC6970;
          
          cursor: pointer;
      }
  }


  .add-review-form-container {
      display: flex;
      flex-flow: column;
      justify-content: center;
      align-items: flex-start;
  }