.soleEditProfile__convertBtn {
  height: 50px;
  border-radius: 8px;
  border: none;
  background-color: rgb(55, 95, 255);
  color: #fff;
  font-weight: 600;
  font-size: 18px;

  &:hover {
    cursor: pointer;
    opacity: 0.8;
  }
}

.firmEditProfile__labelWrap {
  display: flex;
  flex-flow: column;
}
