.NotFound {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: column;
  height: 80vh;
  margin-right: auto;
  margin-left: auto;
}

.not-found-btn {
    margin-top: 15px;
    text-decoration: none;
    font-size: 18px;
    border:none;
    border-radius: 25px;
    border: 1px solid #CF021A;
    padding: 10px 15px;
    color: #CF021A;
    cursor: pointer;

    &:focus {
        outline: none;
    }
    
    &:hover {
        background-color: #CF021A;
        color: #ffffff;
        transition: .2s;
    }
}

