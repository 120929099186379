.lw-portal-text-area-field-container {
    display: flex;
    flex-flow: column;
}

.lw-portal-text-area-field-title {
    font-family: "DM Sans";
    font-size: 15px;
    font-weight: 700;
    color: #362727;
    margin-left: 5px;
}

.lw-portal-text-area-field {
  outline: none;
  font-size: 16px;
  font-family: "DM Sans";
  font-weight: 300;
  background: #FFFFFF;
  color: #111111;
  border-radius: 6px;
  border: 1px solid #D3B9B9;
  padding: 10px 10px;
  width: 440px;
  height: 100px;
}

.lw-portal-text-area-field:focus {
    border: 1px solid #3689FF;
}


.lw-portal-text-area-field-info {
    font-size: 13px;
    font-family: "DM Sans";
    font-weight: 400;
    color: #9b9b9b;
    margin-top: 10px;
    margin-left: 5px;
}

/* MODAL TEXT AREA */
.modal-text-area-field-container {
    display: flex;
    flex-flow: column;
}

.modal-text-area-field-title {
    font-family: "DM Sans";
    font-size: 15px;
    font-weight: 700;
    color: #362727;
    margin-left: 5px;
}

.modal-text-area-field {
    outline: none;
    font-size: 16px;
    font-family: "DM Sans";
    font-weight: 300;
    background: #FFFFFF;
    color: #111111;
    border-radius: 6px;
    border: 1px solid #D3B9B9;
    padding: 10px 10px;
    min-width: 400px;
    min-height: 100px;
}

.modal-text-area-field:focus {
    border: 1px solid #3689FF;
}


.modal-text-area-field-info {
    font-size: 13px;
    font-family: "DM Sans";
    font-weight: 400;
    color: #c2c2c2;
    margin-top: 10px;
    margin-left: 5px;
}


/* edit profile - textarea */
.edit-profile-text-area-input {
    outline: none;
    font-size: 16px;
    font-family: "DM Sans";
    font-weight: 300;
    background: #FFFFFF;
      /* color: #111111; */
    color: #362727;
    border-radius: 6px;
    border: 1px solid #D3B9B9;
    padding: 10px 10px;
    width: 520px;
    max-height: 150px;
}
/* PLACEHOLDER CSS */
::placeholder {
    color: #362727;
}


@media screen and (max-width: 1180px) {
    .lw-portal-text-area-field {
        width: 250px;
    }
}
